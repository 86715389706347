import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from '../../types/user';
import { FormDataProps, SchoolFeesFormData, ChildDetail } from '../../types';
import {
  removeSessionDetails,
  removeTokenDetails,
  storeSessionDetails,
  storeTokenDetails,
} from './../../functions/userSession';
import { RootState } from '../store';

// Define a type for the slice state

// Define the initial state using that type
const initialState: {
  user: UserType | null;
  vendor: UserType | null;
  token: string | null;
  // refreshToken: string | null;
  clientId: string | null;
  customerId: string | null;
  otpData: { otp: string | null; email: string; pass: string };
  loginData: {
    complianceStatus: boolean;
    status: 'Active' | 'Pending' | 'Suspended' | 'Deleted';
    otp: string | null;
    token: string | null;
    // refreshToken: string | null;
  };
  currentRoute: string;
  userType: 'user' | 'provider' | null;
  clientType: 'customer-access' | 'supplier-client' | null;
  complianceData: FormDataProps;
  schoolFeesData: SchoolFeesFormData;
} = {
  user: null,
  vendor: null,
  token: null,
  // refreshToken: null,
  clientId: null,
  customerId: null,
  otpData: { otp: null, email: '', pass: '' },
  loginData: {
    complianceStatus: false,
    status: 'Active',
    otp: null,
    token: null,
    // refreshToken: null,
  },
  userType: null,
  clientType: null,
  currentRoute: '',
  complianceData: {
    VendorId: '',
    BusinessType: null,
    IdentificationType: null,
    IdentificationNumber: '',
    BusinessIdentificationNumber: '',
    PartnershipIdentificationNumber: '',
    PrivateIdentificationNumber: '',
    RegistrationNumber: '',
    BusinessNameNumber: '',
    BusinessIdentificationType: null,
    PartnerIdentificationType: null,
    PrivateIdentificationType: null,
    TaxIdentificationNumber: '',
    FullName: '',
    BusinessName: '',
    ContactAddress: '',
    Country: '',
    State: '',
    City: '',
    PhoneNumber: '',
    uploadedImage: '',
    file: null,
    PartnerCACReport: null,
    PartnerMemo: null,
    PartnerCertOfIncorp: null,
    PartnerTaxId: null,
    PrivateCACReport: null,
    PrivateMemo: null,
    PrivateCertOfIncorp: null,
    PrivateTaxId: null,
    VerificationStatus: '',
    ComplainceBankRequests_AccountOwnerName: '',
    ComplainceBankRequests_BankName: null,
    ComplainceBankRequests_BankAccountNumber: '',
  },
  schoolFeesData: {
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    ChildDetails: [],
    ParentRecords_Occupaction: '',
    ParentRecords_CompanyName: '',
    ParentRecords_Location: '',
    ParentRecords_YearsOfWorkinWithThem: '',
    ParentRecords_CompanyEmail: '',
    ParentRecords_CompanyPhoneNumber: '',
    ParentRecords_PaySlip: [],
    ParentRecords_BankStatement: [],
  },
};

// Actual Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<{ user: UserType }>) {
      state.user = action.payload.user;
      console.log(action.payload.user, 'action.payload.user');
      storeSessionDetails(action.payload.user);
    },
    updateToken(state, action: PayloadAction<{ token: string }>) {
      state.token = action.payload.token;
      // state.refreshToken = action.payload.refreshToken;
      storeTokenDetails(action.payload.token);
    },

    signOut(state) {
      state.vendor = null;
      state.user = null;
      state.token = null;
      state.clientType = null;
      removeSessionDetails();
      removeTokenDetails();
    },
    setOtpData(
      state,
      action: PayloadAction<{ otp: string; email: string; pass: string }>
    ) {
      state.otpData = action.payload;
    },
    setLoginData(
      state,
      action: PayloadAction<{
        complianceStatus: false;
        status: 'Active';
        otp: null;
        token: null;
        // refreshToken: null;
      }>
    ) {
      state.loginData = action.payload;
    },
    setUserType(state, action: PayloadAction<'user' | 'provider'>) {
      state.userType = action.payload;
    },
    setClientId(state, action: PayloadAction<string>) {
      state.clientId = action.payload;
    },
    setCustomerId(state, action: PayloadAction<string | any>) {
      state.customerId = action.payload;
    },
    setClientType(
      state,
      action: PayloadAction<'customer-access' | 'supplier-client'>
    ) {
      state.clientType = action.payload;
    },
    setCurrentRoute(state, action: PayloadAction<string>) {
      state.currentRoute = action.payload;
    },
    setFormData(state, action: PayloadAction<Partial<FormDataProps>>) {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value !== undefined && key in state.complianceData) {
          // @ts-ignore - TypeScript might not infer this correctly, but we know `key` is in `complianceData`
          state.complianceData[key] = value;
        }
      });
    },
    setSchoolFees(state, action: PayloadAction<Partial<SchoolFeesFormData>>) {
      state.schoolFeesData = {
        ...state.schoolFeesData,
        ...action.payload,
      };
    },
  },
});

export const {
  updateUser,
  signOut,
  updateToken,
  setOtpData,
  setUserType,
  setClientId,
  setClientType,
  setLoginData,
  setCurrentRoute,
  setCustomerId,
  setFormData,
  setSchoolFees,
} = userSlice.actions;

export const complianceData = (state: RootState) => state.user.complianceData;
export const schoolFeesData = (state: RootState) => state.user.schoolFeesData;

export default userSlice.reducer;
