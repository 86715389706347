export const API_URL = process.env.REACT_APP_API_URL;
export const SESSION_NAME = process.env.REACT_APP_SESSION_NAME;
export const SESSION_KEY = process.env.REACT_APP_SESSION_KEY;
export const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;
export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME;
export const TEST_KEY = process.env.REACT_APP_TEST_KEYS;
export const PUBLIC_NAME = process.env.REACT_APP_PAYSTACK_PUBLICK_KEY;
export const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const FACEBOOK_CLIENT_SECRET =
  process.env.REACT_APP_FACEBOOK_CLIENT_SECRET;



