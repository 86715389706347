import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { RoutePaths } from '../route-paths';
const LandingPage = lazy(() => import('../../pages'));
const SchoolfeesPage = lazy(() => import('../../pages/user/schoolfees'));
const SchoolfeePage = lazy(
  () => import('../../pages/user/InstitutionSchoolFees')
);
const SchoolFeeLandingPage = lazy(
  () => import('../../pages/SchoolFeeLandingPage')
);

export const homeRoutes: RouteObject[] = [
  {
    path: RoutePaths.HOMEPAGE,
    element: <LandingPage />,
  },
  {
    path: RoutePaths.SCHOOL_FEES,
    element: <SchoolfeesPage />,
  },
  {
    path: RoutePaths.INSTITUTION_SCHOOLFEES,
    element: <SchoolfeePage />,
  },
  { path: RoutePaths.SCHOOLFEES_GETSTARTED, element: <SchoolFeeLandingPage /> },
];
