import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { RoutePaths } from '../route-paths';
import { PrivateVendorRoute } from '../utils';
const DashboardLayout = lazy(() => import('../../layouts/dashboard-layout'));
const VendorServicesPage = lazy(
  () => import('../../pages/vendor/dashboard/services')
);
const VendorSettingsPage = lazy(
  () => import('../../pages/vendor/dashboard/settings')
);
const ProfilePage = lazy(
  () => import('../../pages/vendor/dashboard/profile/Profile')
);
const PasswordResetPage = lazy(
  () => import('../../pages/vendor/dashboard/password-reset/PasswordReset')
);
const PrivacyPage = lazy(() => import('../../pages/vendor/dashboard/settings'));
const VendorTransactionsPage = lazy(
  () => import('../../pages/vendor/dashboard/transactions')
);
const VendorCustomerTransactions = lazy(
  () => import('../../pages/vendor/dashboard/transactions/customerTransaction')
);
const VendorCustomersPage = lazy(
  () => import('../../pages/vendor/dashboard/customers')
);
const VendorDashboardPage = lazy(() => import('../../pages/vendor/dashboard'));
const CustomerDetailsPage = lazy(
  () => import('../../pages/vendor/dashboard/customers/customersMain')
);
const ServiceDetailsPage = lazy(
  () => import('../../pages/vendor/dashboard/services/ServiceDetails')
);

const VendorCookiesPage = lazy(
  () => import('../../pages/vendor/dashboard/legal/Cookies')
);
const VendorPrivacyPage = lazy(
  () => import('../../pages/vendor/dashboard/legal/Privacy')
);
const VendorTermsPage = lazy(
  () => import('../../pages/vendor/dashboard/legal/Terms')
);
const WalletPage = lazy(() => import('../../pages/vendor/dashboard/wallet'));
const vendorDashboardRoutes: RouteObject[] = [
  {
    path: RoutePaths.SERVICE_DETAILS, // Add this new route
    element: <ServiceDetailsPage />,
  },
  {
    path: RoutePaths.PROVIDER_DASHBOARD,
    element: (
      <PrivateVendorRoute>
        <DashboardLayout />
      </PrivateVendorRoute>
    ),
    children: [
      {
        path: RoutePaths.PROVIDER_DASHBOARD,
        element: (
          <PrivateVendorRoute>
            <VendorDashboardPage />
          </PrivateVendorRoute>
        ),
        index: true,
      },
      {
        path: RoutePaths.PROVIDER_SETTINGS,
        element: (
          <PrivateVendorRoute>
            <ProfilePage />
          </PrivateVendorRoute>
        ),
      },
      {
        path: RoutePaths.PROVIDER_PASSWORD_RESET,
        element: (
          <PrivateVendorRoute>
            <PasswordResetPage />
          </PrivateVendorRoute>
        ),
      },
      {
        path: RoutePaths.PROVIDER_PRIVACY_POLICY,
        element: (
          <PrivateVendorRoute>
            <VendorPrivacyPage />
          </PrivateVendorRoute>
        ),
      },
      {
        path: RoutePaths.PROVIDER_COOKIES_POLICY,
        element: (
          <PrivateVendorRoute>
            <VendorCookiesPage />
          </PrivateVendorRoute>
        ),
      },
      {
        path: RoutePaths.PROVIDER_TERMS,
        element: (
          <PrivateVendorRoute>
            <VendorTermsPage />
          </PrivateVendorRoute>
        ),
      },
      {
        path: RoutePaths.PROVIDER_SERVICES,
        element: (
          <PrivateVendorRoute>
            <VendorServicesPage />
          </PrivateVendorRoute>
        ),
      },
      {
        path: RoutePaths.PROVIDER_TRANSACTIONS,
        element: (
          <PrivateVendorRoute>
            <VendorTransactionsPage />
          </PrivateVendorRoute>
        ),
      },
      {
        path: RoutePaths.PROVIDER_WALLET,
        element: (
          <PrivateVendorRoute>
            <WalletPage />
          </PrivateVendorRoute>
        ),
      },
      {
        path: RoutePaths.PROVIDER_TRANSACTIONS_DETAILS,
        element: (
          <PrivateVendorRoute>
            <VendorCustomerTransactions />
          </PrivateVendorRoute>
        ),
      },
      // {
      //   path: RoutePaths.PROVIDER_CUSTOMERS,
      //   element: (
      //     <PrivateVendorRoute>
      //       <VendorCustomersPage />
      //     </PrivateVendorRoute>
      //   ),
      // },
      {
        path: RoutePaths.CUSTOMER_DETAILS, // Add this new route
        element: (
          <PrivateVendorRoute>
            <CustomerDetailsPage />
          </PrivateVendorRoute>
        ),
      },
    ],
  },
];

export default vendorDashboardRoutes;
