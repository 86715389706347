// src/components/NotFound.tsx
import React from "react";
import MainLayout from '../layouts/MainLayout';

const NotFound: React.FC = () => {
  return (
    <MainLayout>
      <div className='flex items-center justify-center h-screen'>
        <h1 className='text-4xl font-bold'>404 - Page Not Found</h1>
      </div>
    </MainLayout>
  );
};

export default NotFound;
