import { ReactNode } from 'react';
import { Element } from 'react-scroll';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';

const MainLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <main className="min-h-screen flex flex-col justify-between">
      <Header />
      {children}
      <Element id="footer" name="footer">
        <Footer />
      </Element>
    </main>
  );
};

export default MainLayout;
