import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { RoutePaths } from '../route-paths';
import { ProtectedUserRoute } from '../utils';
const UserLoginPage = lazy(() => import('../../pages/user/auth/Login'));
const RegisterPage = lazy(() => import('../../pages/user/auth/Register'));
const NigeriaCompiance = lazy(
  () => import('../../pages/user/auth/ComplianceNg')
);
const UsaCompiance = lazy(() => import('../../pages/user/auth/ComplianceUsa'));
const UserAcceptSevice = lazy(
  () => import('../../pages/user/auth/UserAcceptService')
);
const UserRejectSevice = lazy(
  () => import('../../pages/user/auth/UserAcceptService')
);
const ConfirmDashboardPage = lazy(
  () => import('../../pages/user/dashboard/paystackConfirm')
);

const UserEmailVerificationPage = lazy(
  () => import('../../pages/user/auth/EmailVerification')
);
const ChangePassword = lazy(
  () => import('../../pages/user/schoolfees/ChangePassword')
);
const userAuthRoutes: RouteObject[] = [
  {
    path: RoutePaths.LOGIN,
    element: (
      <ProtectedUserRoute>
        <UserLoginPage />
      </ProtectedUserRoute>
    ),
  },
  {
    path: RoutePaths.USER_REGISTER,
    element: (
      <ProtectedUserRoute>
        <RegisterPage />
      </ProtectedUserRoute>
    ),
  },
  {
    path: RoutePaths.USER_EMAIL_VERIFICATION,
    element: (
      <ProtectedUserRoute>
        <UserEmailVerificationPage />
      </ProtectedUserRoute>
    ),
  },
  {
    path: RoutePaths.USER_COMPLIANCE_NIGERIA,
    element: (
      <ProtectedUserRoute>
        <NigeriaCompiance />
      </ProtectedUserRoute>
    ),
  },
  {
    path: RoutePaths.USER_ACCEPT_SERVICE,
    element: (
      <ProtectedUserRoute>
        <UserAcceptSevice />
      </ProtectedUserRoute>
    ),
  },
  {
    path: RoutePaths.USER_PAYSTACK_CONFIRM,
    element: (
      <ProtectedUserRoute>
        <ConfirmDashboardPage />
      </ProtectedUserRoute>
    ),
  },
  {
    path: RoutePaths.USER_REJECT_SERVICE,
    element: (
      <ProtectedUserRoute>
        <UserRejectSevice />
      </ProtectedUserRoute>
    ),
  },
  {
    path: RoutePaths.USER_COMPLIANCE_USA,
    element: (
      <ProtectedUserRoute>
        <UsaCompiance />
      </ProtectedUserRoute>
    ),
  },
  {
    path: RoutePaths.CHANGE_PASSWORD,
    element: (
      <ProtectedUserRoute>
        <ChangePassword />
      </ProtectedUserRoute>
    ),
  },
];

export default userAuthRoutes;
